.skills-container {
    width: 95%;
    min-height: 50em;
    border-radius: 10px;
    background-color: white;
    margin: 6rem auto 3rem auto;
    border: solid #d7dbde 1px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    color: black;
}

.skills {
  width: 33.33%;
}

#jumboHeader {
  font-size: 3.5em;
}

.w-border {
  border-right: solid 1px #d7dbde;
}

.projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
}

@media screen and (max-width: 990px) {

  .skills {
    width: 100%;
  }
  .w-border {
    border-right: none;
    border-bottom: solid 1px #d7dbde;
  }

  #jumboHeader {
    font-size: 3.5em;
  }
}

@media screen and (max-width: 768px) {
  .projects {
    justify-content: center;
  }
}